.wrapper {
  height: 100%;
  position: relative;
  &:hover {
    .menu-item {
      & .icon {
        svg {
          transform: rotateX(180deg);
        }
      }
      &::before {
        width: calc(100% + 10px);
      }
    }
  }

  & .menu-item {
    &:not(a) {
      cursor: default;
    }
    &:hover {
      @apply text-blue-darkest;
    }
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #fff;
    display: flex;
    gap: 0.2rem;
    & .icon {
      padding-top: 0rem;
      svg {
        @apply w-5 h-5 text-blue-light;
        transition: all 0.3s ease-in-out;
      }
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 4px;
      width: 0;
      height: 3px;
      transition: width 0.3s ease-in-out;
      border-radius: 2px;
      @apply bg-blue-light;
    }
  }

  & .submenu-container {
    display: none;
  }
}

.active {
  > .submenu-container {
    display: block;
    &:hover > li > div {
      @apply text-black/60;
      > div > span svg {
        @apply text-blue-light/30;
      }
    }
    & > li > div {
      @apply text-black;
    }
  }
}

.noHover {
  background-color: red!important;
  color: red!important;
}