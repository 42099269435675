.mainFooter {
  background-color: var(--gray-light);
  padding: 2rem 0;
}

.mainFooterContainer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footerLogo {
  width: auto;
  @apply h-[80px] md:h-[65px] aspect-[265/65];
}

.socialLink {
  span {
    transition: 0.5s;
    border-radius: 1000px;
    vertical-align: middle;
    @apply text-white bg-blue-darkest lg:w-[22.5px] lg:h-[22.5px] w-[35px] h-[35px];
    &:hover {
      @apply bg-blue-light;
    }
    svg {
      width: 60%;
      height: 60%;
    }
  }
}

// .socialLink span {
//   color: var(--blue-darkest);
//   display: block;
//   height: 100%;
//   width: 100%;
// }

// .socialLink:hover {
//   background-color: var(--blue-darkest);
// }

// .socialLink:hover a {
//   color: #fff;
// }

.footerTitle {
  font-weight: 500;
  color: var(--blue-darkest);
}

.footerLinks {
  line-height: 1.5;
  margin-top: 0.5rem;
}

.tempNewsletter {
  width: 100%;
  height: auto;
}

.legalFooterLinks {
  background-color: var(--blue-darkest);
  padding: 1rem;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 1.75;
}

.legalFooterLinks a {
  color: #fff;
}

.legalFooterLinks a:hover {
  color: var(--blue-light);
}

.footerDisclaimer {
  background-color: var(--gray-light);
  padding: 2rem 0;
}

.footerDisclaimer p {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}

@media only screen and (max-width: 1200px) {
  .mainFooterContainer,
  .footerDisclaimer p {
    max-width: 95%;
  }
}
