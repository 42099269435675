.wrapper {
  background: #fff;
  height: 100%;
  &:hover {
    .menu-item {
      &--icon {
        svg {
          transform: rotateX(180deg);
        }
      }
    }
  }

  & .menu-item {
    position: relative;
    display: flex;
    max-width: 285px;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
    padding: 0.3rem 0.5rem 0.3rem 0.8rem;
    &:hover {
      @apply bg-blue-darkest text-white;
    }
    > div {
      width: 100%;
    }
    & .icon {
      svg {
        @apply w-4 h-4 text-blue-light;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  & .submenu-container {
    display: none;
  }
}

.active {
  > .menu-item {
    @apply bg-blue-darkest text-white;
    & .icon {
      svg {
        @apply text-blue-light;
      }
    }
    &:hover {
      @apply bg-blue-darkest text-white;
      & .icon {
        svg {
          color: rgb(90 192 238) !important;
        }
      }
    }
  }
  > .submenu-container {
    display: block;
    &:hover ul > li > div {
      @apply text-black/60;
      & .icon {
        svg {
          @apply text-blue-light/30;
        }
      }
    }
    & > li > div {
      @apply text-black;
    }
  }
}
